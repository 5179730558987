import React from "react";
import "./products.scss";
import Experiment from "./experiment-item";
import {injectIntl, FormattedMessage } from "gatsby-plugin-intl";

class Experiments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      xp: [],
      messages: props.intl.messages,
      callbackMount: this.props.callbackMount
    }
  }

  imagesPath = require.context(
    "../../../../src/images",
    false,
    /\.(png|jpe?g|svg)$/
  );

  componentDidMount() {
    // Loop over messages
    let xp = [];
    Object.keys(this.state.messages).map(key => {
      // Get only production items
      if (!key.match(/^xp__item./)) return null;

      // Split the key
      const key_split = key.split(".");

      // Recreate the xp array
      if (!xp[key_split[1]]) xp[key_split[1]] = {};
      xp[key_split[1]][key_split[2]] = this.state.messages[key];

      return null;
    });

    this.setState({xp: xp});
    
    // Callback for parent
    this.state.callbackMount('experiments');
  }

  render() {
    return (
      <div className="section experiments"  data-header-color="dark">
        <div className="section__products">
          <FormattedMessage id="xp__title.section" />
        </div>

        <div className="xp__list">
          {this.state.xp.map((xp, index) => {
            return (
              <Experiment
                key={index}
                xpIcon={this.imagesPath(xp.icon)}
                xpBackground={this.imagesPath(xp.icon)}
                xpTitle={xp.title}
                xpDesc={xp.desc}
              />
            );
          })}
        </div>
      </div>
    );
  } 
}

export default injectIntl(Experiments);